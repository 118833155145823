<template>
  <div>
    <MainSection
        v-if="cms.loaded"
        :title="cmsStore.components.main_section.fgTitle"
        :becomeAFriend="cmsStore.common.common.fgBecomeAFriend"
    />
    <section class="section-about" id="section-about">
      <div class="inner-wrapper">
        <div class="block-about">
          <div class="block-about-left">
            <H2 v-if="cms.loaded" :title="h2._1.title" :color="h2._1.color" :id="h2._1.id"/>
            <p class="color-white">{{ cms.page.fgMillionsOfPeople }}</p>
            <p class="color-white source-statistic">{{ cms.page.fgSourceStatistics }}</p>
          </div>
          <div class="block-about-right">
            <router-link :to="{ name: 'BecomeAFriend'}" class="btn btn-default btn-flex btn-lg btn-animation">
              <span>{{ cmsStore.common.common.fgBecomeAFriend }}</span>
              <p>{{ cms.page.fgJoinNetworkFriends }}</p>
            </router-link>
            <router-link :to="{ name: 'LearnAboutFriends'}" class="btn btn-default btn-flex btn-lg btn-animation">
              <span>{{ cmsStore.common.common.fgLearnAboutFriends }}</span>
              <p>{{ cms.page.fgLearnImpactFriend }}</p>
            </router-link>
          </div>
        </div>
        <p class="section-about-text"></p>
      </div>
    </section>
    <section class="section-support" id="section-support">
      <div class="inner-wrapper section-support-bg">
        <H2 v-if="cms.loaded" class="heading_title" :title="h2._2.title" :color="h2._2.color" :id="h2._2.id"/>
        <div class="support-article">
          <div class="support-article-item" v-for="support in supports">
            <img
                :src="require(`@/assets/images/${support.img}.jpg`)"
                class="img-fluid support-article-img"
            />
            <div class="support-article-body">
              <h3 class="support-article-title">{{ support.title }}</h3>
              <p class="support-article-text">{{ support.text }}</p>
            </div>
          </div>
        </div>
        <div class="support-block-btn">
          <router-link :to="{ name: 'LearnAboutFriends'}" class="btn btn-default">
            <span>{{ cmsStore.common.common.fgLearnAboutFriends }}</span>
          </router-link>
        </div>
      </div>
    </section>
    <section class="section-testimonials">
      <div class="inner-wrapper">
        <H2 v-if="cms.loaded" :title="h2._3.title" :color="h2._3.color" :id="h2._3.id"/>
      </div>
      <div class="testimonials">
        <splide :options="options">
          <splide-slide
              class="testimonials-item"
              v-for="testimonial in testimonials"
          >
            <p class="testimonials-text">{{ testimonial.text }}</p>
            <span class="testimonials-user">{{ testimonial.user }}</span>
          </splide-slide>
        </splide>
      </div>
    </section>
  </div>
</template>

<script>
import {Splide, SplideSlide} from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import H2 from '@/components/H2'
import MainSection from '@/components/home/MainSection'

import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: 'Home',
  components: {
    Splide,
    SplideSlide,
    H2,
    MainSection
  },
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["h2", "login_modal", "main_section"],
          LanguageCode: "en",
          PageName: "home",
          MinistryUpdates: false
        },
        page: {},
      },
      position_support: '',
      position_block_about: '',
      h2: {
        _1: {
          title: "Because There Are Still People Who Don’t Know Jesus Christ",
          color: "color-white",
          id: "t1",
        },
        _2: {
          title: "How Do I Support Gideons as a Friend?",
          color: "color-black",
          id: "t2",
        },
        _3: {
          title: "Testimonials",
          color: "color-white",
          id: "t3",
        },
      },
      options: {
        type: "loop",
        padding: "500px",
        perPage: 1,
        breakpoints: {
          1600: {
            padding: "300px",
          },
          1300: {
            padding: "200px",
          },
          991: {
            padding: "100px",
          },
          768: {
            padding: "15px",
          },
        },
      },
      supports: [
        {
          img: "home-pray",
          title: "Pray",
          text: "Your prayer support helps more than 245,000 men and women carry out the ministry of The Gideons International, and it helps those who receive the life-changing Good News of the Gospel.",
        },
        {
          img: "home-support",
          title: "Support",
          text: "Your financial support equips Gideons with the resources, tools, training, and Scriptures necessary to reach people with the truth of God’s Word — many of whom may otherwise never understand or learn about what it means to have a personal relationship with the Lord.",
        },
        {
          img: "home-share",
          title: "Share",
          text: "Your desire to see people come to Christ becomes a reality when you purchase Scriptures and resources that strengthen your personal witnessing skills and equip you to share your faith.",
        },
      ],
      testimonials: [
        {
          user: "Leanne",
          text: "The value of being a Friend is partnering in soul winning locally and around the world. The Gideons International is the one organization that is focused on reaching nearly every corner of the globe with one common goal, to share the Gospel.",
        },
        {
          user: "Gwen",
          text: "I was in a motel room where I shouldn’t have been when I came across a Gideon-placed Bible. I read it and received Jesus as my Lord and Savior! Over 20 years later, I am a Friend, so I can help provide Bibles for others to receive the same hope of Jesus.",
        },
        {
          user: "Rev. Bender",
          text: "Participating in Friends of Gideons truly is beneficial for all involved. I feel very much a part of the mission of The Gideons International—I’ll remain a Friend of Gideons for the rest of my life.",
        },
      ],
    }
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setAllCmsContent()
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    handleScrollHome() {

      if (document.getElementById('section-support') !== null) {
        let pageOffset = window.pageYOffset;
        let intFrameHeight = window.innerHeight / 2;

        let elem = document.getElementById("section-support");
        let val = this.position_support;
        let offset = val - intFrameHeight;
        if (pageOffset > offset) {
          elem.classList.add("inview");
        }

        let elemAbout = document.getElementById("section-about");
        let valAbput = this.position_block_about;
        let offsetAbput = valAbput - intFrameHeight;
        if (pageOffset > offsetAbput) {
          elemAbout.classList.add("inview-about");
        }
      }
    },
    getSupportPosition() {
      let elem = document.getElementById("section-support");
      let elemBlockAbout = document.getElementById("section-about");
      this.position_support = elem.getBoundingClientRect().top;
      this.position_block_about = elemBlockAbout.getBoundingClientRect().top;
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents
    },
    setAllCmsContent() {
      this.setCmsContentForH2Component()
      this.setCmsContentForSupports()
      this.setCmsContentForTestimonials()
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    setCmsContentForH2Component() {
      this.h2._1.color = this.cms.page.fgSubtitle1Color
      this.h2._1.title = this.cms.page.fgSubtitle1

      this.h2._2.color = this.cms.page.fgSubtitle2Color
      this.h2._2.title = this.cms.page.fgSubtitle2

      this.h2._3.color = this.cms.page.fgSubtitle3Color
      this.h2._3.title = this.cms.page.fgSubtitle3
    },
    setCmsContentForSupports() {
      this.supports[0].text = this.cms.page.fgSupports1Text
      this.supports[0].title = this.cms.page.fgSupports1Title

      this.supports[1].text = this.cms.page.fgSupports2Text
      this.supports[1].title = this.cms.page.fgSupports2Title

      this.supports[2].text = this.cms.page.fgSupports3Text
      this.supports[2].title = this.cms.page.fgSupports3Title
    },
    setCmsContentForTestimonials() {
      this.testimonials[0].text = this.cms.page.fgTestimonials1Text
      this.testimonials[0].user = this.cms.page.fgTestimonials1User

      this.testimonials[1].text = this.cms.page.fgTestimonials2Text
      this.testimonials[1].user = this.cms.page.fgTestimonials2User

      this.testimonials[2].text = this.cms.page.fgTestimonials3Text
      this.testimonials[2].user = this.cms.page.fgTestimonials3User
    },
  },
  mounted() {
    this.designStore.setIsLogo(false)
    this.getSupportPosition();
  },
  async created() {
    let loader = null
    loader = this.$loading.show()
    this.setContentLoaded(false)
    await this.fetchCmsData()
    loader.hide()

    window.addEventListener("scroll", this.handleScrollHome);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScrollHome);
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
  },
}
</script>

<style lang="scss">
@import "../assets/scss/abstracts/_color.scss";
@import "../assets/scss/abstracts/_variables.scss";

.block-about {
  background-color: $blue;

  &-left {
  }

  p {
    margin-bottom: 0;
  }

  .title-underline {
    font-weight: 500;
  }

  .block-about-right {
    a {
      span {
        font-weight: 400;
        color: #0C2E3C;
      }

      p {
        color: #0C2E3C;
        font-weight: 400;
      }
    }
  }
}

.section-about {
  position: relative;
  margin-top: -25px;

  &-text {
    color: $blue-darker;
    font-weight: 500;
    text-align: center;
  }

  .inner-wrapper {
    max-width: 100%;
    width: 1400px;
    margin: 0 auto;
    padding: 0 15px;

    .block-about {
      box-shadow: 0 25px 25px -14px rgba(0, 0, 0, .5);
      @include MQ(L) {
        padding-left: 50px;
        justify-content: space-between;
      }

      &-left {
        @include MQ(L) {
          flex-basis: 50%;
        }
      }

      &-right {
        @include MQ(L) {
          flex-basis: 40%;
        }

        span {
          font-weight: 500;
        }

        a {
          text-align: center;
          filter: drop-shadow(0 3px 5px rgba(0, 0, 0, .2));
          align-items: center;
          @include MQ(L) {
            align-items: flex-start;
            text-align: left;
          }

          p {
            padding-right: 0;
          }
        }
      }
    }
  }

  .btn-animation {
    opacity: 0;
  }

  &.inview-about {
    .btn-animation {
      -webkit-animation: fadeInRight 1s ease forwards;
      animation: fadeInRight 1s ease forwards;

      &:nth-child(1) {
        -webkit-animation-delay: 0.2222222222s;
        animation-delay: 0.2222222222s;
      }

      &:nth-child(2) {
        -webkit-animation-delay: 0.3333333333s;
        animation-delay: 0.3333333333s;
      }
    }
  }
}

.section-support {
  position: relative;
  overflow: hidden;

  &.inview {
    .support-article {
      &-item {
        -webkit-animation: fadeInLeft 1s ease forwards;
        animation: fadeInLeft 1s ease forwards;

        &:nth-child(1) {
          -webkit-animation-delay: 0.2222222222s;
          animation-delay: 0.2222222222s;
        }

        &:nth-child(2) {
          -webkit-animation-delay: 0.3333333333s;
          animation-delay: 0.3333333333s;
        }

        &:nth-child(3) {
          -webkit-animation-delay: 0.4444444444s;
          animation-delay: 0.4444444444s;
        }
      }
    }
  }

  .heading_title {
  }

  .inner-wrapper {
    position: relative;
    width: 1300px;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    .support-article {
      max-width: 100%;

      .support-article-item {
        justify-content: space-between;
        flex-wrap: wrap;
        @include MQ(S) {
          margin-bottom: 40px;
        }

        img {
          flex-basis: 300px;
          @include MQ(S) {
            flex-basis: 100%;
          }
        }

        > div {
          flex-basis: calc(100% - 350px);
          padding: 0;

          h3 {
            margin-bottom: 30px;
            margin-top: 25px;
            @include MQ(S) {
              font-weight: 600;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }

        &:nth-child(1) {
          @include MQ(XL) {
            max-width: 82%;
          }
        }

        &:nth-child(2) {
          @include MQ(XL) {
            max-width: 73%;
          }
        }

        &:nth-child(3) {
          @include MQ(XL) {
            max-width: 70%;
          }
        }
      }
    }
  }
}

.support-article {
  &-item {
    opacity: 0;
  }

  &-text {
    font-weight: normal;
    color: #0c2e3c;
  }
}

.section-testimonials {
  background: $blue;

  .inner-wrapper {
    width: 1300px;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 100%;
  }
}

.testimonials {
  &-item {
    background: white;
    border-left: 10px solid $blue-darker;
    padding: 40px 40px 23px;
    transition: all .33s ease;

    &:not(.is-active) {
      background: #fbf6eb;
      opacity: 0.5;
    }
  }

  &-text {
    font-weight: 400;
    line-height: 35px;
    font-family: $baskerville, serif;
    font-size: 18px;
    @include MQ(M) {
      font-size: 19px;
    }
    @include MQ(L) {
    }
  }

  &-user {
    font-size: 16px;
    font-weight: 700;
    color: $blue-darker;
    text-transform: uppercase;
  }
}

.splide {
  padding: 0;

  &__pagination {
    position: relative;
    bottom: 0;
    margin-top: 75px;

    &__page {
      background: rgba(white, 0.3);
      width: 15px;
      height: 15px;
      margin: 0 7.5px;
      @media screen and (max-width: 575px) {
        margin: 0 4px;
      }

      &.is-active {
        transform: none;
        background: white;
        opacity: 1;
      }

      &:hover {
        background: white;
      }
    }
  }

  &__arrows {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 900px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__arrow {
    display: inline-flex;
    align-items: center;
    opacity: 1;
    width: auto;
    background: transparent;
    margin-top: -10px;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
    }

    svg {
      display: none;
    }

    &--prev {
      left: 0;

      &:before {
        min-width: 40px;
        height: 12px;
        background: url(../assets/images/arrow-slider.svg) no-repeat;
        display: block;
        content: "";
        transform: translate(0);
        transition: transform 0.25s;
        margin-right: 25px;
      }

      &:after {
        content: "Previous";
        font-size: 16px;
        color: white;
        font-weight: 600;
      }

      &:hover {
        opacity: 1;

        &:before {
          transform: translateX(-10px);
        }
      }
    }

    &--next {
      right: 0;

      &:before {
        content: "Next";
        font-size: 16px;
        color: white;
        font-weight: 600;
      }

      &:after {
        min-width: 40px;
        height: 12px;
        background: url(../assets/images/arrow-slider-next.svg) no-repeat;
        display: block;
        content: "";
        transform: translate(0);
        transition: transform 0.25s;
        margin-left: 25px;
      }

      &:hover {
        &:after {
          transform: translateX(10px);
        }
      }
    }
  }
}

.support-block-btn {
  display: flex;
  justify-content: center;
  margin: 25px 0;
  @include MQ(M) {
    justify-content: flex-start;
    margin-left: 350px;
  }
}

@media screen and (min-width: 768px) {
  .testimonials {
    &-item {
      margin-right: 70px;
    }

    &-text {
      // font-size: 25px;
      font-weight: 400;
      line-height: 33px;
      margin-bottom: 40px;
    }
  }
  .splide {
    margin-top: 115px;

    &__arrow {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }
  .section-testimonials {
    padding: 70px 0;
  }
  .support-article {
    // padding: 65px 0;

    &-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }

    &-img {
      min-width: 300px;
      min-height: 235px;
      width: 300px;
      height: 235px;
      object-fit: cover;
    }

    &-body {
      padding-top: 27px;
      padding-left: 60px;
    }

    &-text {
      line-height: 33px;
    }
  }
  .section-about {
    &-text {
      line-height: 20px;
      margin-top: 32px;
    }
  }
  .section-support {
    padding: 30px 0 50px;
  }
}

@media screen and (min-width: 1400px) {
  .section-support {
    &-bg {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 1066px;
        height: 1120px;
        background: url(../assets/images/girl-solo.png) no-repeat;
        bottom: 0;
        right: 0;
        z-index: -1;
        transform: translate(50%, 150px);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section-testimonials {
    padding: 45px 0 54px;
  }
  .testimonials {
    &-item {
      margin-right: 10px;
    }

    &-text {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
    }
  }
  .splide {
    margin-top: 45px;

    &__arrow {
      &--prev {
        left: 15px;
      }

      &--next {
        right: 15px;
      }
    }
  }
  .support-article {
    padding: 15px 0;

    &-item {
      &:not(:last-child) {
        margin-bottom: 45px;
      }
    }

    &-img {
      min-height: 235px;
      width: 100%;
      height: 235px;
      object-fit: cover;
      object-position: center top;
    }

    &-body {
      padding-top: 15px;
    }

    &-title {

    }

    &-text {
      line-height: 30px;
    }
  }
  .section-about {
    &-text {
      line-height: 30px;
      margin-top: 35px;
    }
  }

  .section-support {
    padding: 68px 0 25px;
  }
}

@media screen and (max-width: 991px) {
  .block-about {
    padding: 27px 25px 30px 25px;

    &-right {
      margin-top: 44px;

      .btn-default {
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .block-about {
    padding: 50px;

    &-right {
      margin-top: 30px;
      width: 100%;
      max-width: 100%;
      display: flex;

      .btn-flex {
        width: 50%;
      }

      .btn-default {
        &:first-child {
          margin-right: 15px;
        }

        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .block-about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0 75px 100px;

    &-left {
      max-width: 800px;
    }

    &-right {
      max-width: 650px;
      margin-right: -10px;

      .btn-default {
        &:first-child {
          margin-bottom: 35px;
        }
      }
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

p.source-statistic {
  margin-top: 8px;
}

@media only screen and (min-width: 768px) {
  p.source-statistic {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  p.source-statistic {
    font-size: 13px;
  }
}
</style>