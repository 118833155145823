<template>
  <section class="section-main section-main-animation" id="section-main">
    <div class="section-main-bg">
      <div class="section-main-images"></div>
    </div>
    <div class="container-s section-main-block">
      <div class="line-animate"></div>
      <div class="sub-line"></div>
      <div class="main-block">
        <div class="main-block-inner">
          <img src="../../assets/images/section-main-logo.svg" class="img-fluid element-animation">
          <p class="main-block-text element-animation">{{ title }}</p>
          <router-link :to="{ name: 'BecomeAFriend'}" class="btn btn-default element-animation">{{
              becomeAFriend
            }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainSection",
  props: {
    title: {
      type: String,
      required: true,
      default: 'Support Gideons Worldwide in Reaching More People with the Gospels.',
    },
    becomeAFriend: {
      type: String,
      required: true,
      default: 'Become A Friend',
    }
  },
}
</script>

<style lang="scss">

.section-main {
  position: relative;
  overflow: hidden;
  height: 755px;

  &-bg {
    position: relative;
    z-index: 0;
    height: 100%;

    &:before {
      z-index: 1;
      left: 0;
      position: absolute;
      width: 250px;
      height: 100%;
      top: 0;
      content: "";
      background: #fff;
      background: linear-gradient(
              90deg,
              rgba(white, .7) 50px,
              transparent 100%);
      animation: fadeInCustom 1s ease forwards;
      @include MQ(M) {
        background: linear-gradient(
                90deg,
                rgba(white, 1) 100px,
                transparent 100%);
      }
      @include MQ(L) {
        width: 250px;
        left: calc(20% + 50px);
        background: linear-gradient(
                90deg,
                rgba(white, 1) 0px,
                transparent 100%);
      }
      @media (min-width: 1300px) {
        left: calc(20% + 150px);
      }
      @include MQ(XXL) {
        left: 500px;

      }
    }
  }

  &-images {
    position: relative;
    height: 100%;
    // full color
    &:before {
      content: '';
      display: block;
      height: 885px;
      background: url(../../assets/images/home_hero2.jpg) no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
      animation: fadeInCustom 2s 0s ease forwards;

    }

    // boy cutout
    &:after {
      content: '';
      display: block;
      height: 885px;
      background: url(../../assets/images/home_hero1.png) no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
      animation: fadeIn 1s ease forwards 3s;
    }

    &:before,
    &:after {
      height: 100%;
      background-position: 30% 50%;
      @include MQ(M) {
        background-position: calc(0% + 0px) 50%;
      }
      @include MQ(L) {
        background-position: calc(20% + 400px) 50%;
      }
      @media (min-width: 1300px) {
        background-position: calc(20% + 500px) 50%;
      }
    }
  }

  &-block {
    position: absolute !important;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
  }
}

.main-block {
  z-index: 1;
  position: relative;
  width: 100%;

  &-inner {
    width: 100%;
    margin: 280px auto 0;
    max-width: 100%;
    @include MQ(L) {
      margin: 150px auto 0;
      width: calc(100% - 100px);
    }

    img {
      width: 200px;
      max-width: 90%;
      @include MQ(M) {
        widows: 300px;
      }
      @include MQ(L) {
        width: 380px;
      }
    }

    p.main-block-text {
      max-width: 300px;
      color: white;
      font-weight: bold;
      margin: 33px 0 35px;
      @include MQ(L) {
        line-height: 1.4;
        font-size: 28px;
        max-width: 450px;
      }
    }
  }
}

.line-animate {
  position: absolute;
  height: 2700px;
  background-color: $blue-darker;
  top: 0;
  z-index: 0;
  @media (max-width: 991px) {
    width: 300px;
    margin-left: -5px;
    opacity: 0;
    animation: fadeLine 1s ease forwards 2s;
  }
  @media (min-width: 992px) {
    animation: fadeLineMax 1s ease forwards 2s;
    opacity: 0;
    width: 500px;
    margin-left: 310px;
  }
}

.sub-line {
  position: absolute;
  width: 10px;
  height: 2700px;
  background-color: $yellow-darker;
  top: 0;
  z-index: 0;
  @media (max-width: 991px) {
    opacity: 0;
    margin-left: -5px;
    margin-left: 415px;
    animation: fadeSubLine 1s ease forwards 2.33s;
  }
  @media (min-width: 992px) {
    opacity: 0;
    margin-left: 35px;
    margin-left: 550px;
    animation: fadeSubLineMax 1s ease forwards 2.33s;
  }
}

.section-main-animation {
  .element-animation {
    opacity: 0;
    animation: fadeInLeft 1s ease forwards;

    &:nth-child(1) {
      animation-delay: 0.4444444444s;
    }

    &:nth-child(2) {
      animation-delay: 0.7777777777s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }

  .section-main {

  }
}

@media screen and (max-width: 991px) {
  .main-block {
    &-text {
      font-size: 15px;
      max-width: 240px;
    }
  }
  .section-main {
    height: 740px;

  }
}


@keyframes bgFadeInLeftS {
  from {
    opacity: 0;
    background-position: 50% 50%;
  }
  to {
    opacity: 1;
    background-position: 30% 50%;
  }
}

@keyframes bgFadeInLeftM {
  from {
    opacity: 0;
    background-position: 50% 50%;
  }
  to {
    opacity: 1;
    background-position: 0 50%;
  }
}

@keyframes bgFadeInLeftL {
  0% {
    opacity: 0;
    background-position: 50% 50%;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    background-position: calc(20% + 400px) 50%;
  }
}

@keyframes fadeLine {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translateX(208%) translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translateX(208%) translateY(-32%);
  }
}

@keyframes fadeInCustom {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  50% {
    opacity: 0;
    filter: grayscale(0%);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    filter: grayscale(100%);
  }
}

@keyframes fadeSubLine {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(6000%, -100%);
  }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translate(6000%, -32%);
  }
}

@keyframes fadeLineMax {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(95%, -100%);
  }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translate(95%, -32%);
  }
}

@keyframes fadeSubLineMax {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(7500%, -100%);
  }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translate(7500%, -32%);
  }
}


</style>